<template>
  <div id="LiveList">
    
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="allList"
    >
      <div class="list" v-for="(i,item) in getDocList" :key="item">
        <img :src="Global.changeImg(i.thumbimgurl)"/>
        <div>
          <div class="van-multi-ellipsis--l2"><span>{{i.livetime_start.split(' ')[0]}}</span>{{i.title}}</div>
          <div>
            <span><van-icon name="eye-o" style="margin:0.02rem 0.05rem 0 0;font-size:0.14rem" />{{i.views}}</span>
            <span><van-icon name="clock-o" style="margin:0.02rem 0.05rem 0 0;font-size:0.14rem" />{{Global.formatDateL((i.livetime_start).replace(/-/g, '/'),(i.livetime_end).replace(/-/g, '/'))}}</span>
          </div>
        </div>
      </div>
    </van-list>
    <!-- <div class="list">
      <img src="../../assets/image/home/index.png"/>
      <div>
        <div class="van-multi-ellipsis--l2"><span>06/15</span>保险科技企业服务|保险营销服务赛道 的机会与挑战</div>
        <div>
          <span><van-icon name="eye-o" style="margin:0.02rem 0.05rem 0 0;font-size:0.14rem" />432</span>
          <span><van-icon name="clock-o" style="margin:0.02rem 0.05rem 0 0;font-size:0.14rem" />1h25min</span>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'LiveList',
   data(){
    return{
      getDocList:[],//公司列表
      finished:false,
      loading:true,
      next_page_url:'',
    }
  },
  mounted(){
    // 直播列表
    this.axios({
      method: 'GET',
      url: '/live/getLiveList?type=2',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.getDocList = res.data.data.data;
        this.moreLetter = false
        this.loading = false
      }
    })
  },
  methods:{
     onLoad(){
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url:this.next_page_url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
  }
}
</script>
<style lang="less" scoped>
#LiveList{
  padding: 0 0.15rem;
  background: #23252E;
  .allList{
    >div:last-child{
      border: none;
    }
    >div{
      padding: 0.15rem 0;
      display: flex;
      align-items: center;
      border-bottom:.1px solid #5C5D6E61;
      >img{
        width: 0.8rem;
        margin-right: 0.12rem;
      }
      >div{
        display: flex;
        flex-direction: column;
        line-height: 0.18rem;
        color: #F6F7FD;
        span{
          color: #727387;
          margin-right: 0.1rem;
        }
        >div:last-child{
          margin-top: 0.07rem;
          >span{
            color: #727387;
            margin-right: 0.2rem;
          }
        }
      }
      
    }
  }
}
</style>